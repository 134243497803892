:root {
    --primary_colour: 32, 67, 129;
    --secondary_colour : 23, 147, 205;
    --header_colour:222, 234, 248;
    --table_hover : 162, 212, 236;
    --table_row_primary : 222, 234, 248;
    --table_row_secondary : 255, 255, 255;
    --table_text_colour : 0, 0, 0;
    --button_text_colour : 255, 255, 255;
    --menu_primary_colour: 55, 56, 58;
    --menu_secondary_colour: 0, 0, 0;
    --update_center_colour: 255, 255, 255;
    --navigation_text_colour: 32, 67, 129;
    --primary_icon_colour: 32, 67, 129;
    --header_navigation_text_colour: 32, 67, 129;
    --mobile_menu_colour: 244, 243, 242;
}

$themes: (
    gocabinets: (
        ui-primary-color: rgba(var(--primary_colour), 1),
        ui-primary-color-transparent: rgba(var(--primary_colour), .6),
        ui-primary-color-transparent-2: rgba(var(--primary_colour), .07),
        ui-primary-color-transparent-3: rgba(var(--primary_colour), .2),
        ui-secondary-color: rgba(var(--secondary_colour), 1),
        ui-primary-icon-color : rgba(var(--primary_icon_colour), 1),
        ui-secondary-color-transparent: rgba(var(--secondary_colour), .5),
        ui-secondary-color-transparent-2: rgba(var(--secondary_colour), .2),
        ui-secondary-color-table-hover: rgba(var(--table_hover), 1),
        ui-danger-color: #C92434,
        ui-info-color: #57585A,
        ui-stripe-first-color: rgba(var(--menu_primary_colour), 1),
        ui-stripe-second-color: rgba(var(--menu_secondary_colour), 1),
        ui-text-color: #ffffff,
        ui-gray: #57585A,
        ui-header-background: rgba(var(--header_colour), 1),
        ui-sidebar-background: #F4F3F2,
        ui-tr-even-background: rgba(var(--table_row_secondary), 1),
        ui-tr-odd-background: rgba(var(--table_row_primary), 1),
        ui-table-text-color: rgba(var(--table_text_colour), 1),
        ui-update-center-background: rgba(var(--update_center_colour), 1),
        ui-nav-color: rgba(var(--navigation_text_colour), 1),
        ui-highlight-color: #B0C9E7,
        ui-main-menu-width: 225px,
        sidebar-switch-width: 80px,
        sidebar-right-width: 400px,
        sidebar-menu-height: 3.5vw,
        sidebar-icon-size: 2vw,
        button-title-icon-size: 30px,
        button-text-color: rgba(var(--button_text_colour), 1),
        primary-header-image-size: 30px,
        primary-header-border-radius: 20px,
        primary-header-padding: 5px,
        secondary-header-border-radius: 10px,
        secondary-header-padding: 5px,
        ui-scrollbar-width: 10px,
        ui-scrollbar-color: #888,
        ui-scrollbar-background: #f1f1f1,
        ui-scrollbar-hover-color: #555,
        ui-scrollbar-curve: 10px,
        ui-scrollbar-track-box-shadow: inset 0 0 6px rgba(0,0,0,0.3),
        sidebar-header-nav-color: rgba(var(--header_navigation_text_colour), 1),
        mobile-menu-background: rgba(var(--mobile_menu_colour), 1),
    )
);
